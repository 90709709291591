import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Qualification } from '../.../../../../../shared/types/Qualification';

export const useQualificationListStore = defineStore('qualificationList', () => {
  const qualificationList = ref<Qualification[]>([]);

  const certificates = computed(() => {
    return qualificationList.value?.map((item) => ({
      image: {
        src: item.thumbnail,
        width: '93',
        height: '140',
        preview: item.filename,
        alt: item.name,
      },
      title: item.name,
      desc: item.description,
      link: item.url,
      website: item.website,
    }));
  });

  return { qualificationList, certificates };
});
