<script setup lang="ts">
import type { CompanyDetails } from 'shared/types/CompanyDetails';
import { inject } from 'vue';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { useUserStore } from '../../stores/UserStore';
import { storeToRefs } from 'pinia';

const { userId } = storeToRefs(useUserStore());

defineProps<{
  companyDetails: CompanyDetails | undefined;
  hasKap: boolean;
}>();

const { t } = useI18n();
const { dataAttributesGA } = useGoogleAnalytics();

const statistic = inject(statisticKey);
</script>

<template>
  <a
    v-if="hasKap"
    class="cursor-pointer"
    v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyName') }"
    @click="
      statistic?.sendCounter({
        counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_LAW_OFFICE,
        entityId: userId,
      })
    ">
    {{ companyDetails?.name }}
  </a>
  <figure v-else>
    <div>
      <figcaption>{{ companyDetails?.name }}</figcaption>
    </div>
  </figure>
</template>
