<script setup lang="ts">
import { computed } from 'vue';
import { AnwLists } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBalanceScale } from "@fortawesome/pro-light-svg-icons";
import { usePracticeAreasStore } from '@/shared/stores/PracticeAreasStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useLawyerStore } from '@/shared/stores/LawyerStore';

const { practiceAreas } = usePracticeAreasStore();
const { profileProductId } = useProfileProductId();
const { profile } = useLawyerStore();
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();

const getSpecialisation = computed(() => {
  return getTranslationByProductId('listSpecialisation', profileProductId, {
    n: profileProductId === 'lawyer' ? getGenderValueForTranslation(profile.sex) : 'company'
  });
});

const practiceAreaNames = practiceAreas
  .map((item) => ({
    content: item.name,
    isSpecial: item.isSpecial
  }));

practiceAreas.forEach((item, index) => {
  if (practiceAreaNames[index].isSpecial) {
    practiceAreaNames[index].content = getSpecialisation.value + ' ' + item.name;
  }
});
</script>

<template>
  <div v-if="practiceAreaNames.length" class="flex gap-4 anw-text-p1 text-neutral-700 mb-6">
    <div>
      <FontAwesomeIcon :icon="faBalanceScale" aria-hidden="true" fixed-width />
    </div>
    <AnwLists :args="practiceAreaNames" :appearance="'dots'" />
  </div>
</template>
