<script setup lang="ts">
import { computed } from 'vue';
import { AnwReadMoreExternal } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';
import { useI18n } from 'vue-i18n';
import { OpeningHours } from '@/shared/helper/OpeningHoursHelper';
import { WorkingTime } from '@shared/types/WorkingTime';
import { useImage } from '@shared/composables/image';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { storeToRefs } from 'pinia';

const { companyDetails } = useCompanyDetailsStore();

const { t } = useI18n();

const href = computed<string>(() => companyDetails?.uri || '');
const text = computed<string>(() => companyDetails?.shortAbout || '');
const { modifiedContactDetails } = storeToRefs(useContactDetailsStore());
const workingTime = modifiedContactDetails.value[0].workingTime as WorkingTime;
const companyOpenings = computed(() => new OpeningHours(workingTime).getOpeningHours());

const imageUrlHost = import.meta.env.VITE_IMAGES_URL;
const { computedImagePath } = useImage(imageUrlHost, companyDetails?.imagePath || '', COMPANY);
</script>

<template>
  <div class="grid gap-4 py-4" :class="{ 'border-b': text }">
    <div class="flex flex-wrap justify-between gap-x-4 gap-y-6 pb-4 border-b">
      <figure>
        <a :href="href"> <img :src="computedImagePath(600, 48)" class="sm:!h-12" :alt="companyDetails?.name" /></a>
      </figure>
      <div v-if="companyOpenings" class="flex 2xl:items-start gap-3 2xl:pr-10 text-neutral-600 anw-text-p2">
        <FontAwesomeIcon :icon="faClock" class="text-[20px] text-neutral-600" aria-hidden="true" />
        <div class="flex flex-col gap-y-0.5">
          <span v-for="(week, hours, idx) in companyOpenings" :key="idx" class="flex gap-6 justify-between">
            <div v-html="week"></div>
            <div v-html="hours" class="min-w-fit"></div>
          </span>
        </div>
      </div>
    </div>
    <div v-if="text" class="pt-4">
      <AnwReadMoreExternal
        :target-blank=false
        class="anw-wysiwyg line-clamp-4"
        :href="href"
        :text="text"
        :show-follow-link="!!href"
        :label-follow-link="t('companyReadMore')"
        :title="companyDetails?.name" />
    </div>
  </div>
</template>
