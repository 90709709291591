import { computed, ref } from 'vue';
import { useGalleryStore } from '@/modules/profile-page/stores/GalleryStore';
import { storeToRefs } from 'pinia';

const imageUrlHost = import.meta.env.VITE_IMAGES_URL;

export const useCarousel = () => {
  const galleryStore = useGalleryStore();
  const { gallery } = storeToRefs(galleryStore);

  const carouselImages = gallery
    ? computed(() => {
        return gallery.value.map((item) => ({
          src: `${imageUrlHost}/image/gallery/fit-height/${item.filename.substring(
            item.filename.lastIndexOf('/') + 1
          )}?height=800`,
          thumbnail: `${imageUrlHost}/image/gallery/fit-height/${item.filename.substring(
            item.filename.lastIndexOf('/') + 1
          )}?height=160`,
          title: item.title,
          copyright: item.copyright,
          alt: item.altText,
          height: 80,
        }));
      })
    : ref([]);

  const carouselBreakpoints = {
    0: {
      itemsToShow: 1.5,
    },
    320: {
      itemsToShow: 2,
    },
    420: {
      itemsToShow: 3,
    },
    520: {
      itemsToShow: 4,
    },
    768: {
      itemsToShow: 5,
    },
  };
  return {
    carouselBreakpoints,
    carouselImages,
  };
};
