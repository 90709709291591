<script setup lang="ts">
import { computed } from 'vue';
import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { COMPANY, LAWYER } from '@shared/constants/ProfileProductId';
import { useImage } from '@shared/composables/image';
import CompanyWithLogo from './CompanyWithLogo.vue';
import CompanyWithoutLogo from './CompanyWithoutLogo.vue';

const { profileProductId } = useProfileProductId();
const { companyDetails, hasKap } = useCompanyDetailsStore();

const imageUrlHost = import.meta.env.VITE_IMAGES_URL;
const { computedImagePath, isGenericLogo } = useImage(imageUrlHost, companyDetails?.imagePath || '', COMPANY);

const imagePath = computedImagePath.value(600, 48);

const componentConfig = computed(() => {
  if (!isGenericLogo)
    return {
      component: CompanyWithLogo,
      imgUrl: imagePath,
      className: 'py-2 ps-2 pe-4 gap-y-2 gap-x-4 flex flex-wrap',
    };
  return { component: CompanyWithoutLogo, className: 'p-2' };
});
</script>

<template>
  <div v-if="profileProductId === LAWYER" class="flex">
    <component
      :hasKap="hasKap"
      :is="componentConfig.component"
      :companyDetails="companyDetails!"
      :class="['bg-neutral-50 rounded-xl items-center text-sm/[22px] font-semibold', componentConfig.className]"
      :imgUrl="componentConfig?.imgUrl" />
  </div>
</template>
