import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { CompanyColleague } from 'shared/types/CompanyDetails';
import { ImageHelper } from '@shared/helper/ImageHelper';

export const useColleagueListStore = defineStore('colleagueList', () => {
  const colleagueList = ref<CompanyColleague[] | never[]>([]);
  const imageUrlHost = import.meta.env.VITE_IMAGES_URL;

  const transformedColleagueList = computed(
    () => (size: number) =>
      colleagueList.value?.map((colleague) => ({
        id: colleague.id?.toString(),
        uri: colleague.uri,
        imageUri: `${imageUrlHost}${ImageHelper().generateThumbnail(colleague.imagePath)}?side-length=${size}`,
        fullName: colleague.fullName,
        ratingAverage: colleague.ratingAverage,
        ratingCount: colleague.ratingCount,
        isRateable: colleague.isRateable,
        sex: colleague.sex,
      }))
  );

  return { colleagueList, transformedColleagueList };
});
