import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import type { Address } from '@shared/types/Address';
import { storeToRefs } from 'pinia';

export const AddressHelper = () => {
  const getMainAddress = (): string => {
    const { street, houseNumber, zipCode, city } = storeToRefs(useContactDetailsStore()).modifiedContactDetails.value[0]
      .address;

    return `${street} ${houseNumber}, ${zipCode} ${city}`;
  };
  const getAdditionalAddress = () => {
    const { modifiedContactDetails } = storeToRefs(useContactDetailsStore());
    return modifiedContactDetails.value.slice(1).map((location) => ({
      id: location.id.toString(),
      address: ` ${location.address.zipCode} ${location.address.city}`,
    }));
  };

  const formattedAddress = (address: Address, breakLine: boolean = false): string => {
    return `${address.street} ${address.houseNumber}${breakLine ? '<br>' : ''} ${address.zipCode} ${address.city}`;
  };

  return {
    getMainAddress,
    getAdditionalAddress,
    formattedAddress,
  };
};
