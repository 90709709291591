<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { AnwHeading, AnwCertificate } from '@anwalt.de/tailwind-design-system';
import { faAwardSimple } from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';
import { useQualificationListStore } from '@/modules/profile-page/stores/QualificationListStore';

const { t } = useI18n();

const qualificationListStore = useQualificationListStore();
const { qualificationList, certificates } = storeToRefs(qualificationListStore);

const isPDF = (url: string) => {
  return url.endsWith('.pdf');
};
</script>

<template>
  <section v-if="qualificationList.length">
    <AnwHeading :heading="t('certificatesHeading')" tag="h2" :icon="faAwardSimple" headingClasses="mb-4" />
    <ul>
      <li v-for="(certificate, index) in certificates" :key="index" class="mt-4">
        <AnwCertificate
          :image="certificate.image"
          :title="certificate.title"
          :desc="certificate.desc"
          :link="certificate.link"
          :website="certificate.website"
          :lightbox="!isPDF(certificate.link)" />
      </li>
    </ul>
  </section>
</template>
