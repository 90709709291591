<script setup lang="ts">
import { AnwButton } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMap } from "@fortawesome/pro-light-svg-icons";
import { useI18n } from 'vue-i18n';
import { useCookie } from '@/modules/profile-page/composables/useCookie';

const { t } = useI18n();
const { acceptCookies } = useCookie();
</script>
<template>
  <div class="flex flex-col items-center justify-center bg-neutral-50 p-3 h-[280px] rounded-lg">
    <FontAwesomeIcon :icon="faMap" class="text-[30px]" aria-hidden="true" />
    <div
      class="mt-4 sm:mt-10 lg:mt-4 text-center text-sm sm:text-base text-neutral-600 font-normal"
      v-html="t('map.noCookiesWarning')"></div>
    <AnwButton
      appearance="secondaryOutline"
      :label="t('map.acceptCookies')"
      class="mt-3 font-normal text-base w-full sm:w-auto"
      @click="acceptCookies()" />
  </div>
</template>
